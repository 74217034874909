import React, {ReactElement, useEffect} from 'react';
import {useAuth} from "../context/authContext";
import {Link, useNavigate} from "react-router-dom";
import {
    FaBusAlt, FaCar,
    FaClipboard,
    FaDoorOpen,
    FaExchangeAlt,
    FaPlane,
    FaSignInAlt,
    FaUserGraduate,
    FaWarehouse
} from "react-icons/fa";
import Card from "../components/Card";
import {FcBarChart} from "react-icons/fc";

export type Links = {
    title: string;
    link: string;
    icon: ReactElement
}

const cardsData:Links[] = [
    { title: "Leave Application", link: "https://docs.google.com/forms/d/e/1FAIpQLSevtr9TWQBsX6N-5na-cuVYWWcprKHrXHj_23bNUiFEhJCwNQ/viewform", icon: <FaClipboard />
    },
    { title: "Gate Pass", link: "https://docs.google.com/forms/d/e/1FAIpQLSdrpJhnADcyT5xXNeClZLatI7W4fCvtsYsf8iwYFTm2raM_8Q/viewform", icon: <FaSignInAlt /> },
    { title: "Bus Part Authorization", link: "https://docs.google.com/forms/d/e/1FAIpQLScN6K5v4yesirCwv9lKlmoV6fFlDz61t4ix1jdsGPRoAG6NeQ/viewform", icon: <FaBusAlt /> },
    { title: "Exit Protocols", link: "https://docs.google.com/forms/d/e/1FAIpQLSeN0EFyFKwJhDqOtIBponRDmlgRWM_ee0VU7iYk2urJFA8oKg/viewform", icon: <FaDoorOpen /> },
    { title: "Travel Authorization", link: "https://docs.google.com/forms/d/e/1FAIpQLSc3pbocZwMOmixlulgtnpsx2WvY3j3-utbXmgBsEPoBDr-vmw/viewform", icon: <FaPlane /> },
    { title: "Skilling and Capacity Development", link: "https://docs.google.com/forms/d/e/1FAIpQLSf2vKVMRxfp_0N1RhiQgZBybDlOT3noMrC7t5myvSuE14JPiw/viewform", icon: <FaUserGraduate /> },
    { title: "Asset Transfer", link: "https://forms.gle/XL8anBy8gZ8GcQ6G8", icon: <FaExchangeAlt /> },
    { title: "Warehouse Production Planning and Control System", link: "https://docs.google.com/forms/d/e/1FAIpQLScVVVzB8tee0Ybi8jTnEaUVA6UiHuxPMbOUmOlzGSXDmBqEUQ/viewform", icon: <FaWarehouse /> },
    { title: "Vehicle Nomination Form", link: "https://docs.google.com/forms/d/e/1FAIpQLSfZOUqKGnDeam-4e_-rLKfb-KCit4oX-aDlT9Oks_mTirfBSA/viewform", icon: <FaCar /> },
    { title: "Performance Management System", link: "https://performance.kiiramotors.com", icon: <FcBarChart /> }
];


const Dashboard = () => {
    const {employeeI} = useAuth()
    const navigate = useNavigate();
    useEffect(() => {
        if (!employeeI) navigate("/login", { replace: true });
    })
    return (
        <div className={`w-full h-full flex flex-col items-center relative`}>
            <div className={`absolute left-8 top-8 w-fit h-fit px-4 py-1.5 ring-2 ring-orange-500 bg-orange-500 hover:bg-orange-600 flex flex-col items-center rounded-md text-white text-base font-semibold`}>
                <Link to={"/logout"}>Logout</Link>
            </div>
            <h1 className={`mt-24 md:mt-10 text-2xl lg:text-4xl`}><span className={`text-orange-500 font-semibold`}>{employeeI?.fname}</span> Welcome to KIIS.</h1>
            <div className="mt-2 md:mt-10 w-full h-fit dashboard">
                {cardsData.map((card, index) => (
                    <Card key={index} data={card}/>
                ))}
            </div>

        </div>
    );
};

export default Dashboard;